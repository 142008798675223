import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Layout from '../../../components/layout';
export const _frontmatter = {
  "title": "Advanced"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Advanced`}</h1>
    <p>{`If you're a contributor and want to begin learning about
the interals, you're in the right place.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/docs/advanced/how-it-works/"
        }}>{`How it works`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/docs/advanced/transforms/"
        }}>{`Transforms`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/docs/advanced/queries/"
        }}>{`Queries`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/docs/advanced/blocks-docs-generation/"
        }}>{`Blocks docs generation`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      